import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './tri_mate_logo.png'; // Adjust the path as needed
import { Container, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS


import HomePage from './HomePage';
import PaceCalculationPage from './PaceCalculationPage';
import TrainingPlanPage from './TrainingPlanPage';
import PoseEstimationPage from './PoseEstimationPage';

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#00796b',
    },
    secondary: {
      main: '#ff3d00',
    },
  },
  typography: {
    fontFamily: 'Arial',
    h5: {
      fontWeight: 600,
    },
  },
});

function App() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  
/*
  const [apiResponse, setApiResponse] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
*/    
  const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);

  const [swimDistance, setSwimDistance] = useState("");
  const [swimTime, setSwimTime] = useState("");
  const [swimPace, setSwimPace] = useState("");
  const [bikeDistance, setBikeDistance] = useState("");
  const [bikeTime, setBikeTime] = useState("");
  const [bikePace, setBikePace] = useState("");
  const [runDistance, setRunDistance] = useState("");
  const [runTime, setRunTime] = useState("");
  const [runPace, setRunPace] = useState("");
  const [distanceUnit, setDistanceUnit] = useState("km"); // State variable for distance unit
  const [transition1, setTransition1] = useState("00:00:00");
  const [transition2, setTransition2] = useState("00:00:00");

  const [marathonDistance, setMarathonDistance] = useState(42.2);
  const [halfMarathonDistance, setHalfMarathonDistance] = useState(21.1);


  const handleTabChange = (event, tabIndex) => {
    setCurrentTabIndex(tabIndex);
    setCurrentSubTabIndex(0); // Reset sub tab index when switching main tabs
    //setDataLoaded(false); // Reset dataLoaded when switching tabs
    setSwimDistance("");
    setSwimTime("");
    setSwimPace("");
    setBikeDistance("");
    setBikeTime("");
    setBikePace("");
    setRunDistance("");
    setRunTime("");
    setRunPace("");
    setDistanceUnit("km"); // Reset distance unit
  };
  useEffect(() => {
    if (distanceUnit === 'mi') {
      setMarathonDistance(26.2);
      setHalfMarathonDistance(13.1);
    } else {
      setMarathonDistance(42.2);
      setHalfMarathonDistance(21.1);
    }
  }, [distanceUnit]);
  
  return (
    <Router>
      <div>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand as={Link} to="/">
            <img
              src={logo}
              height="50"
              className="d-inline-block align-top"
              alt="Logo"
            />
            TriMate
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={NavLink} to="/" exact activeClassName="active">Home</Nav.Link>
              <Nav.Link as={NavLink} to="/pace-calculation" activeClassName="active">Pace Calculation</Nav.Link>
              <Nav.Link as={NavLink} to="/training-plan" activeClassName="active">Training Plan</Nav.Link>
              <Nav.Link as={NavLink} to="/pose-estimation" activeClassName="active">Pose Estimation</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container fluid>
          <Routes>
            <Route path="/pace-calculation" element={<PaceCalculationPage />} />
            <Route path="/training-plan" element={<TrainingPlanPage />} />
            <Route path="/pose-estimation" element={<PoseEstimationPage />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;
 