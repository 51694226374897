// TrainingPlanPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Box, Tab, Tabs, Typography, TextField, Button, Grid,
    Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import axios from 'axios';

function TrainingPlanPage() {

    const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable
    const [errorMessage, setErrorMessage] = useState("");
    const [exportFormat, setExportFormat] = useState('pdf');


    const [apiResponseHtml, setApiResponseHtml] = useState(""); // Changed to store HTML response

    const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);
    const [swimDistance, setSwimDistance] = useState("");
    const [swimTime, setSwimTime] = useState("");
    const [swimPace, setSwimPace] = useState("");
    const [bikeDistance, setBikeDistance] = useState("");
    const [bikeTime, setBikeTime] = useState("");
    const [bikePace, setBikePace] = useState("");
    const [runDistance, setRunDistance] = useState("");
    const [runTime, setRunTime] = useState("");
    const [runPace, setRunPace] = useState("");
    const [distanceUnit, setDistanceUnit] = useState("km"); // State variable for distance unit
    const [longTrainingDay, setLongTrainingDay] = useState("Saturday");
    const [dynamicLink, setDynamicLink] = useState("");


    const [transition1, setTransition1] = useState("00:00:00");
    const [transition2, setTransition2] = useState("00:00:00");
    const [raceDate, setRaceDate] = useState(new Date().toISOString().slice(0, 10)); // default to today's date in YYYY-MM-DD format



    const [distanceType, setDistanceType] = useState('');

    const handleDistanceSelection = (event) => {
        const value = event.target.value;
        setDistanceType(value);
        if (value === 'longDistance') {
            setRunDistance(42.2); // Marathon
            setBikeDistance(180); // Typical full Ironman bike distance in kilometers
            setSwimDistance(3.8); // Full Ironman swim distance in kilometers
        }
        if (value === 'middleDistance') {
            setRunDistance(21.1); // Marathon
            setBikeDistance(90); // Typical full Ironman bike distance in kilometers
            setSwimDistance(1.9); // Full Ironman swim distance in kilometers
        }
    };


    const handleRunDistanceChange = (event) => {
        setRunDistance(event.target.value);
    };

    const [marathonDistance, setMarathonDistance] = useState(42.2);
    const [halfMarathonDistance, setHalfMarathonDistance] = useState(21.1);

    const handleSwimTimeChange = (event) => {
        setSwimTime(event.target.value);
    };

    const handleSwimPaceChange = (event) => {
        setSwimPace(event.target.value);
    };

    const handleBikeTimeChange = (event) => {
        setBikeTime(event.target.value);
    };

    const handleBikePaceChange = (event) => {
        setBikePace(event.target.value);
    };

    const handleRunTimeChange = (event) => {
        setRunTime(event.target.value);
    };

    const handleRunPaceChange = (event) => {
        setRunPace(event.target.value);
    };

    const handleDistanceUnitChange = (event) => {
        setDistanceUnit(event.target.value);
    };

    const handleLongTrainingDayChange = (event) => {
        setLongTrainingDay(event.target.value);
    };


    const handleSubTabChange = (event, subTabIndex) => {
        setCurrentSubTabIndex(subTabIndex);
        setSwimDistance("");
        setSwimTime("");
        setSwimPace("");
        setBikeDistance("");
        setBikeTime("");
        setBikePace("");
        setRunDistance("");
        setRunTime("");
        setRunPace("");
        
        setDistanceUnit("km"); // Reset distance unit

    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Construct formData here
            const formData = {
                swimDistance,
                swimTime,
                swimPace,
                bikeDistance,
                bikeTime,
                bikePace,
                runDistance,
                runTime,
                runPace,
                distanceUnit,
                transition1,
                transition2
            };
    
            // Call the first API (handleTriathlonSubmit)
            await handleTriathlonSubmit(formData); // pass formData if needed
    
            // Call the second API (handleTrainingPlanSubmit)
            await handleTrainingPlanSubmit(); // pass formData

             // Update the URL with the new parameters and current tab
            const linkData = { 
                ...formData, 
                distanceType: currentSubTabIndex === 0 ? distanceType : undefined,  // Include distanceType only for Triathlon
                tab: currentSubTabIndex === 0 ? "Triathlon" : "Run"
            };
            const queryParams = new URLSearchParams(linkData).toString();
            navigate(location.pathname + "?" + queryParams, { replace: true });
        } catch (error) {
            console.error("Error during API requests:", error);
            setErrorMessage("Error: " + error.message);
        }
    };
    
    const navigate = useNavigate(); // Get the navigate function
    const location = useLocation();

    useEffect(() => {
        // Extract query parameters from the URL
        const params = new URLSearchParams(location.search);

        // Update state variables based on query parameters only if the values are not already set
        if (params.toString()) {
            setSwimDistance(swimDistance || params.get("swimDistance") || "");
            setSwimTime(swimTime || params.get("swimTime") || "");
            setSwimPace(swimPace || params.get("swimPace") || "");
            setBikeDistance(bikeDistance || params.get("bikeDistance") || "");
            setBikeTime(bikeTime || params.get("bikeTime") || "");
            setBikePace(bikePace || params.get("bikePace") || "");
            setRunDistance(runDistance || params.get("runDistance") || "");
            setRunTime(runTime || params.get("runTime") || "");
            setRunPace(runPace || params.get("runPace") || "");
            setDistanceUnit(distanceUnit || params.get("distanceUnit") || "km");
            setRaceDate(raceDate || params.get("raceDate") || new Date().toISOString().slice(0, 10));
            setLongTrainingDay(longTrainingDay || params.get("longTrainingDay") || "Saturday");

            // Update tab based on query parameter or default to Triathlon
            const tabParam = params.get("tab") || "Triathlon";
            setCurrentSubTabIndex(tabParam === "Triathlon" ? 0 : 1); 
            
            setDistanceType(distanceType || params.get("distanceType") || '');

            // Update the URL to include the query parameters
            navigate(location.pathname + "?" + params.toString(), { replace: true });
        }
    }, [location.search]); // This effect runs only when location.search changes


    const handleTriathlonSubmit = async () => {
        try {
            const formData = {
                swimDistance,
                swimTime,
                swimPace,
                bikeDistance,
                bikeTime,
                bikePace,
                runDistance,
                runTime,
                runPace,
                distanceUnit,
                transition1,
                transition2
              };
            const response = await axios.get(`${apiUrl}/pace-calc`, { params: formData });
            console.log("Triathlon API response:", response.data);
    
            setBikeDistance(response.data.bikeDistance || bikeDistance);
            setBikeTime(response.data.bikeTime || bikeTime);
            setBikePace(response.data.bikePace || bikePace);
            setSwimDistance(response.data.swimDistance || swimDistance);
            setSwimTime(response.data.swimTime || swimTime);
            setSwimPace(response.data.swimPace || swimPace);
            setRunDistance(response.data.runDistance || runDistance);
            setRunTime(response.data.runTime || runTime);
            setRunPace(response.data.runPace || runPace);
            setDistanceUnit(response.data.distanceUnit || distanceUnit);
            setTransition1(response.data.transition1 || transition1);
            setTransition2(response.data.transition2 || transition2);

            

        } catch (error) {
            console.error("API error:", error);
            setErrorMessage("Error: " + error.message);
        }
    };
    
    const handleTrainingPlanSubmit = async () => {
        const formData = {
            runDistance, 
            runTime, 
            runPace, 
            bikeDistance,
            bikeTime,
            bikePace,
            swimDistance,
            swimTime,
            swimPace,
            distanceUnit,
            raceDate,
            longTrainingDay
        };

        try {
            const response = await axios.get(`${apiUrl}/training-plan-html`, { params: formData });
            setApiResponseHtml(response.data); 

            // Update the URL with the new parameters and current tab
            const linkData = { 
                ...formData, 
                distanceType: currentSubTabIndex === 0 ? distanceType : undefined,  // Include distanceType only for Triathlon
                tab: currentSubTabIndex === 0 ? "Triathlon" : "Run"
            };
            const queryParams = new URLSearchParams(linkData).toString();
            navigate(location.pathname + "?" + queryParams, { replace: true });
        } catch (error) {
            console.error("Error during API request:", error);
            setErrorMessage("Error: " + error.message);
        }
    };

    const downloadTrainingPlanFile = async () => {
        if (!['pdf', 'html'].includes(exportFormat)) {
            setErrorMessage('Please select a format to export.');
            return;
        }
        try {
            const response = await axios.get(`${apiUrl}/export-file`, {
                params: { 
                    swimDistance, swimTime, swimPace, bikeDistance, bikeTime, bikePace, 
                    runDistance, runTime, runPace, distanceUnit, raceDate, longTrainingDay, format: exportFormat
                },
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `TrainingPlan.${exportFormat}`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error(`Error downloading ${exportFormat} file:`, error);
            setErrorMessage(`Failed to download ${exportFormat} file. Please try again.`);
        }
    };

    return (
        <div>
            <Box sx={{ display: "flex" }}>
                <Tabs
                    value={currentSubTabIndex}
                    onChange={handleSubTabChange}
                    orientation="vertical"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                >
                    <Tab label="Triathlon" />
                    <Tab label="Run" />
                </Tabs>
                
                {currentSubTabIndex === 0 && (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h5">Triathlon</Typography>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel id="distance-label">Distance</InputLabel>
                                                    <Select
                                                        labelId="distance-label"
                                                        id="distance"
                                                        value={distanceType}
                                                        onChange={handleDistanceSelection}
                                                        label="Distance"
                                                    >
                                                        <MenuItem value="longDistance">Long Distance</MenuItem>
                                                        <MenuItem value="middleDistance">Middle Distance</MenuItem>
                                                    </Select>
                                                <Typography variant="h6">Distance Unit</Typography>
                                                <Select
                                                    value={distanceUnit}
                                                    onChange={handleDistanceUnitChange}
                                                    label="Distance Unit"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    sx={{ width: '100px' }}
                                                >
                                                    <MenuItem value="km">km</MenuItem>
                                                    {/*  <MenuItem value="mi">miles</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                            <Typography variant="h6">Date of race</Typography>
                                            <TextField
                                                label="Race Date"
                                                type="date"
                                                value={raceDate}
                                                onChange={(event) => setRaceDate(event.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                sx={{ width: '180px' }} // Adjust width as needed
                                            />
                                            <Typography variant="h6">Day of long training</Typography>
                                            <Select
                                                value={longTrainingDay}
                                                onChange={handleLongTrainingDayChange}
                                                label="Long Traning Day"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                sx={{ width: '100px' }}
                                            >
                                                <MenuItem value="Monday">Monday</MenuItem>
                                                <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                <MenuItem value="Thursday">Thursday</MenuItem>
                                                <MenuItem value="Friday">Friday</MenuItem>
                                                <MenuItem value="Saturday">Saturday</MenuItem>
                                                <MenuItem value="Sunday">Sunday</MenuItem>
                                                {/*  <MenuItem value="mi">miles</MenuItem> */}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                            <FormControl variant="outlined" margin="normal" size="small" sx={{ minWidth: 120 }}>
                                                <InputLabel id="export-format-label">Export As</InputLabel>
                                                <Select
                                                    labelId="export-format-label"
                                                    id="export-format"
                                                    value={exportFormat}
                                                    onChange={(event) => setExportFormat(event.target.value)}
                                                >
                                                    <MenuItem value="pdf">PDF</MenuItem>
                                                    {/* <MenuItem value="html">HTML</MenuItem>*/}
                                                </Select>
                                            </FormControl>
                                            
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    if (['pdf', 'html'].includes(exportFormat)) {
                                                        downloadTrainingPlanFile(exportFormat);
                                                    } else {
                                                        setErrorMessage('Please select a format to export.');
                                                    }
                                                }}
                                                sx={{ height: '40px' }} // Adjust as necessary to align with the form control
                                            >
                                                Export
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center">
                                        {/* Swim Section */}
                                        <Grid item xs={4}>
                                            <Typography variant="h6">Swim</Typography>
                                            <TextField
                                                label="Time (hh:mm:ss)"
                                                value={swimTime}
                                                onChange={handleSwimTimeChange}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                label="Pace (mm:ss per 100m)"
                                                value={swimPace}
                                                onChange={handleSwimPaceChange}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        {/* Bike Section */}
                                        <Grid item xs={4}>
                                            <Typography variant="h6">Bike</Typography>
                                            <TextField
                                                label="Time (hh:mm:ss)"
                                                value={bikeTime}
                                                onChange={handleBikeTimeChange}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                label="Pace (km/h)"
                                                value={bikePace}
                                                onChange={handleBikePaceChange}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                        {/* Run Section */}
                                        <Grid item xs={4}>
                                            <Typography variant="h6">Run</Typography>
                                            <TextField
                                                label="Time (hh:mm:ss)"
                                                value={runTime}
                                                onChange={handleRunTimeChange}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                            <TextField
                                                label="Pace (min/km)"
                                                value={runPace}
                                                onChange={handleRunPaceChange}
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" sx={{ marginTop: '10px' }}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        {/* Display the API response HTML content */}
                        <div style={{ marginTop: '20px', marginLeft: '50px' }} dangerouslySetInnerHTML={{ __html: apiResponseHtml }} />
                        <div id="message" style={{ color: 'red', marginTop: '20px', marginLeft: '50px' }}>
                            {errorMessage}
                        </div>
                        {/* Display the dynamic link */}
                        {dynamicLink}
                    </Box>
                )}
                {currentSubTabIndex === 1 && (
                    <Box sx={{ p: 3 }}>
                        <Typography variant="h5">Run</Typography>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Grid container spacing={2} direction="column">
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={8}>
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel id="distance-label">Distance</InputLabel>
                                                <Select
                                                    labelId="distance-label"
                                                    id="distance"
                                                    value={runDistance}
                                                    onChange={handleRunDistanceChange}
                                                    label="Distance"
                                                >
                                                    <MenuItem value={marathonDistance}>Marathon</MenuItem>
                                                    <MenuItem value={halfMarathonDistance}>Half-marathon</MenuItem>
                                                </Select>
                                                <Typography variant="h6">Distance Unit</Typography>
                                                <Select
                                                    value={distanceUnit}
                                                    onChange={handleDistanceUnitChange}
                                                    label="Distance Unit"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    sx={{ width: '100px' }}
                                                >
                                                    <MenuItem value="km">km</MenuItem>
                                                    {/*  <MenuItem value="mi">miles</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                            <Typography variant="h6">Date of race</Typography>
                                            <TextField
                                                label="Race Date"
                                                type="date"
                                                value={raceDate}
                                                onChange={(event) => setRaceDate(event.target.value)}
                                                variant="outlined"
                                                margin="normal"
                                                sx={{ width: '180px' }} // Adjust width as needed
                                            />
                                            <Typography variant="h6">Day of long training</Typography>
                                            <Select
                                                value={longTrainingDay}
                                                onChange={handleLongTrainingDayChange}
                                                label="Long Traning Day"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                sx={{ width: '100px' }}
                                            >
                                                <MenuItem value="Monday">Monday</MenuItem>
                                                <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                <MenuItem value="Thursday">Thursday</MenuItem>
                                                <MenuItem value="Friday">Friday</MenuItem>
                                                <MenuItem value="Saturday">Saturday</MenuItem>
                                                <MenuItem value="Sunday">Sunday</MenuItem>
                                                {/*  <MenuItem value="mi">miles</MenuItem> */}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                                            <FormControl variant="outlined" margin="normal" size="small" sx={{ minWidth: 120 }}>
                                                <InputLabel id="export-format-label">Export As</InputLabel>
                                                <Select
                                                    labelId="export-format-label"
                                                    id="export-format"
                                                    value={exportFormat}
                                                    onChange={(event) => setExportFormat(event.target.value)}
                                                >
                                                    <MenuItem value="pdf">PDF</MenuItem>
                                                    {/* <MenuItem value="html">HTML</MenuItem>*/}
                                                </Select>
                                            </FormControl>
                                            
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    if (['pdf', 'html'].includes(exportFormat)) {
                                                        downloadTrainingPlanFile(exportFormat);
                                                    } else {
                                                        setErrorMessage('Please select a format to export.');
                                                    }
                                                }}
                                                sx={{ height: '40px' }} // Adjust as necessary to align with the form control
                                            >
                                                Export
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Time (hh:mm:ss)"
                                        value={runTime}
                                        onChange={handleRunTimeChange}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Pace (mm:ss)"
                                        value={runPace}
                                        onChange={handleRunPaceChange}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type="submit" sx={{ marginTop: '10px' }}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        {/* Display the API response HTML content */}
                        <div style={{ marginTop: '20px', marginLeft: '50px' }} dangerouslySetInnerHTML={{ __html: apiResponseHtml }} />
                        <div id="message" style={{ color: 'red', marginTop: '20px', marginLeft: '50px' }}>
                            {errorMessage}
                        </div>
                    </Box>
                )}


            </Box>
        </div>
    );
}

export default TrainingPlanPage;