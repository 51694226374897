// PaceCalculationPage.js
import React, { useState } from 'react';
import {
    Typography, Grid, Box, Tab, Tabs, TextField, Select, MenuItem, Button
  } from '@mui/material';
import axios from 'axios';


function PaceCalculationPage() {

    const [currentSubTabIndex, setCurrentSubTabIndex] = useState(0);

    const [swimDistance, setSwimDistance] = useState("");
    const [swimTime, setSwimTime] = useState("");
    const [swimPace, setSwimPace] = useState("");
    const [bikeDistance, setBikeDistance] = useState("");
    const [bikeTime, setBikeTime] = useState("");
    const [bikePace, setBikePace] = useState("");
    const [runDistance, setRunDistance] = useState("");
    const [runTime, setRunTime] = useState("");
    const [runPace, setRunPace] = useState("");
    const [distanceUnit, setDistanceUnit] = useState("km"); // State variable for distance unit
    const [transition1, setTransition1] = useState("00:00:00");
    const [transition2, setTransition2] = useState("00:00:00");

    const apiUrl = process.env.REACT_APP_API_URL; // Access the environment variable

  const handleSubTabChange = (event, subTabIndex) => {
    setCurrentSubTabIndex(subTabIndex);
    setSwimDistance("");
    setSwimTime("");
    setSwimPace("");
    setBikeDistance("");
    setBikeTime("");
    setBikePace("");
    setRunDistance("");
    setRunTime("");
    setRunPace("");
    
    setDistanceUnit("km"); // Reset distance unit

  };

  const handleTriathlonSubmit = () => {
    const formData = {
      swimDistance,
      swimTime,
      swimPace,
      bikeDistance,
      bikeTime,
      bikePace,
      runDistance,
      runTime,
      runPace,
      distanceUnit,
      transition1,
      transition2
    };
  
    axios.get(`${apiUrl}/pace-calc`, { params: formData })
      .then(response => {
        // Handle the response from the API
        const { swimDistance, swimTime, swimPace, bikeDistance, bikeTime, bikePace, runDistance, runTime, runPace, totalTime } = response.data;
        
        setSwimDistance(swimDistance);
        setSwimTime(swimTime);
        setSwimPace(swimPace);
        setBikeDistance(bikeDistance);
        setBikeTime(bikeTime);
        setBikePace(bikePace);
        setRunDistance(runDistance);
        setRunTime(runTime);
        setRunPace(runPace);
        
        // Display a success message
        const messageElement = document.getElementById("message");
        messageElement.innerText = `Total race duration is: ${totalTime}`;

        messageElement.style.color = 'black';
        
        console.log("API response:", response.data);
      })
      .catch(error => {
        // Handle any errors that occur during the API call
        console.error("API error:", error);
  
        // Display an error message
        const messageElement = document.getElementById("message");
        messageElement.innerText = "Error: " + error.message;
        messageElement.style.color = 'red';
      });
  
    console.log("Triathlon form submitted!");
  };

  const handleSwimDistanceChange = (event) => {
    setSwimDistance(event.target.value);
  };

  const handleSwimTimeChange = (event) => {
    setSwimTime(event.target.value);
  };

  const handleSwimPaceChange = (event) => {
    setSwimPace(event.target.value);
  };

  const handleBikeDistanceChange = (event) => {
    setBikeDistance(event.target.value);
  };

  const handleBikeTimeChange = (event) => {
    setBikeTime(event.target.value);
  };

  const handleBikePaceChange = (event) => {
    setBikePace(event.target.value);
  };

  const handleRunDistanceChange = (event) => {
    setRunDistance(event.target.value);
  };

  const handleRunTimeChange = (event) => {
    setRunTime(event.target.value);
  };

  const handleRunPaceChange = (event) => {
    setRunPace(event.target.value);
  };

  const handleDistanceUnitChange = (event) => {
    setDistanceUnit(event.target.value);
  };

  return (
    <div>
      {/* Add more content for your PaceCalculationPage here */}
      <Box sx={{ display: "flex" }}>
          <Tabs
          value={currentSubTabIndex}
          onChange={handleSubTabChange}
          orientation="vertical" // Set orientation to vertical for sub tabs
          sx={{ borderRight: 1, borderColor: "divider", width: '200px' }} // EDIT HERE: Increase the width to ensure 'Triathlon' is visible
        >
          <Tab label="Triathlon" />
          <Tab label="Duathlon" />
          <Tab label="Run" />
          <Tab label="Bike" />
          <Tab label="Swim" />
        </Tabs>

          {currentSubTabIndex === 0 && (
            <Box sx={{ p: 3}}>
              <Typography variant="h5">Triathlon</Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="h6">Swim</Typography>
                    <TextField
                      label="Distance"
                      value={swimDistance}
                      onChange={handleSwimDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={swimTime}
                      onChange={handleSwimTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (mm:ss/100m)"
                      value={swimPace}
                      onChange={handleSwimPaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Bike</Typography>
                    <TextField
                      label="Distance"
                      value={bikeDistance}
                      onChange={handleBikeDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={bikeTime}
                      onChange={handleBikeTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (kph/mph)"
                      value={bikePace}
                      onChange={handleBikePaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Run</Typography>
                    <TextField
                      label="Distance"
                      value={runDistance}
                      onChange={handleRunDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={runTime}
                      onChange={handleRunTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (mm:ss)"
                      value={runPace}
                      onChange={handleRunPaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6">Distance Unit</Typography>
                <Select
                  value={distanceUnit}
                  onChange={handleDistanceUnitChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  sx={{ width: '100px' }} 
                >
                  <MenuItem value="km">km</MenuItem>
                  <MenuItem value="mi">miles</MenuItem>
                </Select>

                <Grid item xs={4}>
                  <Typography variant="h6">Transition 1</Typography>
                  <TextField
                    label="Duration"
                    value={transition1}
                    onChange={(e) => setTransition1(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    sx={{ width: 200 }} // Set the width to 200 pixels
                  />
                </Grid>
                {/* Add Transition 2 field */}
                <Grid item xs={4}>
                  <Typography variant="h6">Transition 2</Typography>
                  <TextField
                    label="Duration"
                    value={transition2}
                    onChange={(e) => setTransition2(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    sx={{ width: 200 }} // Set the width to 200 pixels
                  />
                </Grid>
                
                <Button variant="contained" onClick={handleTriathlonSubmit} sx={{ marginLeft: '10px' }}>
                  Submit
                </Button>
              </form>
              <div id="message" style={{ color: 'black' }}></div>

            </Box>
          )}

          {currentSubTabIndex === 1 && (
            <Box sx={{ p: 3 }}>
              <Typography variant="h5">Duathlon</Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="h6">Bike</Typography>
                    <TextField
                      label="Distance"
                      value={bikeDistance}
                      onChange={handleBikeDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={bikeTime}
                      onChange={handleBikeTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (kph/mph)"
                      value={bikePace}
                      onChange={handleBikePaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Run</Typography>
                    <TextField
                      label="Distance"
                      value={runDistance}
                      onChange={handleRunDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={runTime}
                      onChange={handleRunTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (mm:ss)"
                      value={runPace}
                      onChange={handleRunPaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6">Distance Unit</Typography>
                <Select
                  value={distanceUnit}
                  onChange={handleDistanceUnitChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  sx={{ width: '100px' }} 
                >
                  <MenuItem value="km">km</MenuItem>
                  <MenuItem value="mi">miles</MenuItem>
                </Select>
                <Grid item xs={4}>
                  <Typography variant="h6">Transition 1</Typography>
                  <TextField
                    label="Duration"
                    value={transition1}
                    onChange={(e) => setTransition1(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    sx={{ width: 200 }} // Set the width to 200 pixels
                  />
                </Grid>
                <Button variant="contained" onClick={handleTriathlonSubmit} sx={{ marginLeft: '10px' }}>
                  Submit
                </Button>
              </form>
              <div id="message" style={{ color: 'black' }}></div>
            </Box>
          )}

          {currentSubTabIndex === 2 && (
            <Box sx={{ p: 3 }}>
              <Typography variant="h5">Run</Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Distance"
                      value={runDistance}
                      onChange={handleRunDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={runTime}
                      onChange={handleRunTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (mm:ss)"
                      value={runPace}
                      onChange={handleRunPaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6">Distance Unit</Typography>
                <Select
                  value={distanceUnit}
                  onChange={handleDistanceUnitChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  sx={{ width: '100px' }} 
                >
                  <MenuItem value="km">km</MenuItem>
                  <MenuItem value="mi">miles</MenuItem>
                </Select>
                <Button variant="contained" onClick={handleTriathlonSubmit} sx={{ marginLeft: '10px' }}>
                  Submit
                </Button>
              </form>
              <div id="message" style={{ color: 'black' }}></div>
            </Box>
          )}

          {currentSubTabIndex === 3 && (
            <Box sx={{ p: 3 }}>
              <Typography variant="h5">Bike</Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Distance"
                      value={bikeDistance}
                      onChange={handleBikeDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={bikeTime}
                      onChange={handleBikeTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (kmh/mph)"
                      value={bikePace}
                      onChange={handleBikePaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6">Distance Unit</Typography>
                <Select
                  value={distanceUnit}
                  onChange={handleDistanceUnitChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  sx={{ width: '100px' }} 
                >
                  <MenuItem value="km">km</MenuItem>
                  <MenuItem value="mi">miles</MenuItem>
                </Select>
                <Button variant="contained" onClick={handleTriathlonSubmit} sx={{ marginLeft: '10px' }}>
                  Submit
                </Button>
              </form>
              <div id="message" style={{ color: 'black' }}></div>
            </Box>
          )}

          {currentSubTabIndex === 4 && (
            <Box sx={{ p: 3 }}>
              <Typography variant="h5">Swim</Typography>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Distance"
                      value={swimDistance}
                      onChange={handleSwimDistanceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Time (hh:mm:ss)"
                      value={swimTime}
                      onChange={handleSwimTimeChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Pace (mm:ss/100m)"
                      value={swimPace}
                      onChange={handleSwimPaceChange}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6">Distance Unit</Typography>
                <Select
                  value={distanceUnit}
                  onChange={handleDistanceUnitChange}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  sx={{ width: '100px' }} 
                >
                  <MenuItem value="km">km</MenuItem>
                  <MenuItem value="mi">miles</MenuItem>
                </Select>
                <Button variant="contained" onClick={handleTriathlonSubmit} sx={{ marginLeft: '10px' }}>
                  Submit
                </Button>
              </form>
              <div id="message" style={{ color: 'black' }}></div>
            </Box>
          )}
        </Box>
    </div>
  );
}

export default PaceCalculationPage;
